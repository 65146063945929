.root {
    z-index: 100000;
    position: absolute;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 5vh;
    text-align: center;
    flex-direction: column;
}

.iconContainer {
    background-size: contain;
    background-repeat: no-repeat;
    width: 15vh;
    height: 15vh;
}