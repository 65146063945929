.root {
  position: relative;
}

.handsContainer {
  position: absolute;
  display: flex;
  margin-left: -10%;
  margin-top: -10%;
  transition: opacity 0.3s;
}

.showHands {
  opacity: 1;
}

.hideHands {
  opacity: 0;
}

.ready {
  width: 12vw;
  text-align: center;
  font-size: 3vw;
  position: absolute;
  left: -2vw;
  text-align: center;
  overflow: hidden;
}