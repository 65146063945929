.root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  top: calc(50% - 3.5vw);
  position: absolute;
  animation: goUp 3s forwards ease-in-out;
  animation-delay: 0.3s;
  width: 40vw;
  height: 14vw;
  background-size: contain;
  background-repeat: no-repeat;
}

.animationContainer {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: center;
  animation: bodyFadeIn 2s forwards ease-out;
}

.bodysContainer {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  opacity: 0;
  width: 71.3vw;
  animation: bodyFadeIn 2s forwards ease-out;
  animation-delay: 0.3s;
}

.flowerContainer {
  display: flex;
  width: 70vw;
  justify-content: space-around;
  position: absolute;
  opacity: 0;
  animation: bodyFadeIn 2s forwards ease-out;
  bottom: 18.7vw;
  animation-delay: 0.3s;
}

.body {
  height: 22vw;
  width: 14vw;
  background-size: contain;
  z-index: -1;
  background-repeat: no-repeat;
}

.hideBodyAnim {
  animation: hideBody 1s forwards;
}

@keyframes hideBody {
  0% {
    opacity: 1;
  }
  100% {
    transform: translate3d(0px, 22vw, 0px);
    opacity: 0;
  }
}

.startContainer {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.startButton {
  animation: fadeIn 2s;
  transform: translate3d(0px, -7vw, 0px);
  font-size: 4.5vw;
  border: 1px solid transparent;
  transition: 0.3s border;
}

.startButton:hover {
 border-bottom: 1px solid white; 
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bodyFadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0px, 22vw, 0px);
  }
  100% {
    opacity: 1;
  }
}

.titleOut {
  animation: goOutOfScreen 1s;
}

.playOut {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0;
  }
}

@keyframes goOutOfScreen {
  0% {
    top: 5%;
  }
  100% {
    top: -30%;
  }
}

@keyframes goUp {
  0% {}
  100% {
    top: 5%;
  }
}