.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: appear 0.8s;
}

@keyframes appear {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.winner {
  background-size: contain;
  width: 10vw;
  height: 10vw;
  background-repeat: no-repeat;
}

.winnerText {
  color: white;
  font-size: 6vw;
}

.noteableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 600;
}

.noteablePlayerImage {
  background-size: contain;
  width: 5vw;
  height: 5vw;
  background-repeat: no-repeat;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-top: 1vw;
  margin-right: 1vw;
  margin-left: 1vw;
  cursor: pointer;
  color: white;
  font-size: 3vw;
  transition: 0.3s border;
  border: 1px solid transparent;
}

.button:hover {
  border-bottom: 1px solid white;
}

.creditsButton {
  cursor: pointer;
  color: white;
  font-size: 3vw;
  transition: 0.3s border;
  border: 1px solid transparent;
  margin-top: 1vw;
}

.creditsButton:hover {
  border-bottom: 1px solid white;
}