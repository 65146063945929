@keyframes appear {
    0% {
        transform: scale(5);
        opacity: 0.3;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.handContainerLeft {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding-left: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.animateIn {
    animation: appear 1.3s;
}

.handContainerRight {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 12px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.big {
    width: 48%;
}