.root {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  width: 40vw;
  height: 14vw;
  background-size: contain;
  background-repeat: no-repeat;
  top: calc(50% - 3.5vw);
  position: absolute;
}

.percentage {
  font-size: 5vw;
  position: absolute;
  bottom: 10%;
  cursor: wait;
}

.start {
  font-size: 5vw;
  position: absolute;
  bottom: 10%;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s border;
}

.start:hover {
  border-bottom: 1px solid white;
}

.startSpectator {
  bottom: 2%;
}