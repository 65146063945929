.root {
  z-index: 111111;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5%;
  display: flex;
}

.iconContainer {
  width: 1.3vw;
  height: 1.3vw;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 16px;
  cursor: pointer;
  transition: 0.3s border;
  border-bottom: 1px solid transparent;
}

.iconContainer:hover {
  border-bottom: 1px solid white;
}

.unmute {
  padding: 8px;
  margin: 8px;
}

.contactUs {
  text-decoration: none;
  color: white;
  width: unset;
  font-size: 1.5vw;
}