.root {
  display: flex;
  height: 70%;
  justify-content: space-around;
}

.handContainerLeft {
  position: absolute;
  left: 1vw;
}

.infoContainer {
  text-align: center;
  position: absolute;
  width: 40vw;
}

.handContainerRight {
  position: absolute;
  right: 1vw;
}