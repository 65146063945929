.root {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: appear forwards 0.5s;
  background-color: rgba(0, 0, 0, 0.6);
}

.item {
  animation: scaleAnim 0.8s, appear 0.8s;
}


@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleAnim {
  0% {transform: scale(2);}
  100% {
    transform: scale(1);
  }
}
