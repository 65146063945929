.root {
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
}

.textContainer {
  margin-top: 20vh;
  animation: fadeIn 1s;
  font-size: 5vw;
}

.selectPlayers {
  background-size: contain;
  background-repeat: no-repeat;
  width: 40vw;
  height: 11vw;
}

.playerImageContainer {
  cursor: pointer;
  /* use this in player container to exactly match locations from loading */
  display: flex;
  width: 70vw;
  justify-content: space-around;
  position: absolute;
  bottom: 18.7vw;
}

@keyframes waiting {
  0% {}
  50% {
    transform: translate3d(0, -80%, 0);
  }
  100% {}
}

.credits {
  position: absolute;
  bottom: 2vw;
  font-size: 4vw;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: 0.3s border;
}

.credits:hover {
  border-bottom: 1px solid white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}