.container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

/* TODO! support phone by making it column */

.mainCreditsWrapper {
  display: flex;
}

.column {
  margin: 3vw;
  text-align: center;
}

.row {
  margin: 20px 0
}

.role {
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid #f7aaf0;
  padding-bottom: 2px;
  font-size: 2vw;
  min-width: 200px;
  margin-bottom: 10px;
  font-weight: bold;
}

.secondaryRole {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5vw;
  min-width: 100px;
}

.name {
  text-align: center;
  margin: 0.2vw;
  font-size: 2.5vw;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 0.3s;
  cursor: pointer;
  text-decoration: none;
  color: white;
  text-align: center;
}

.name:hover {
  border-bottom: 1px solid white;
  text-decoration: double
}

.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.githubLink {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.cloud {
  background-size: cover;
  background-repeat: no-repeat;
  width: 48%;
  height: 24%;
  position: absolute;
  right: 0;
  bottom: 0;
  animation: fadeIn 1s;
}

.eyes {
  width: 20%;
  height: 20%;
  position: absolute;
  right: 6%;
  background-size: contain;
  background-repeat: no-repeat;
  animation: float 3s infinite ease-in-out, fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    bottom: 6%;
  }
  50% {
    bottom: 9%;
  }
  100% {
    bottom: 6%;
  }
}

.backButton {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  font-size: 2vw;
}