.root {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 0;
  height: 6vw;
  display: flex;
}

.iconContainer {
  width: 6vw;
  height: 6vw;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 2vw;
}