.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.keyboard {
  margin-top: -15%;
  border: 2px solid lightgray;
  box-sizing: border-box;
  border-radius: 3px;
  width: 2.5vw;
  height: 2.5vw;
  max-width: 35px;
  max-height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  font-family: ConcertOne;
}

.keyboardPressed {
  background-color: gray;
}

.starContainer {
  /* Half the hand size */
  width: 2.5vw;
  position: relative;
}

.starContainerLarge {
  /* Half the hand size */
  width: 15vw;
}

.hand {
  width: 5vw;
  height: 5vw;
  background-repeat: no-repeat;
  background-size: contain;
}

.handLarge {  
  width: 30vw;
  height: 30vw;
}

/* Maybe replace shitty stars with svgs */

.starSVG {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: starAnimation 1s infinite linear;
  opacity: 0;
  fill: red;
}

.starSVGLarge {
  animation: starAnimationLarge 1s infinite linear;
}

@keyframes starAnimation {
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    height: 1.5vw;
    width: 2vw;
    opacity: 1;
  }
  100% {
    height: 0vw;
    width: 0vw;
    visibility: visible;
    opacity: 0;
    background-size: 0 0;
    /* Half the width, and downwards */
    transform: translate3d(1vw, 100px, 0);
  }
}

@keyframes starAnimationLarge {
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    height: 9vw;
    width: 12vw;
    opacity: 1;
  }
  100% {
    height: 0vw;
    width: 0vw;
    visibility: visible;
    opacity: 0;
    background-size: 0 0;
    
    transform: translate3d(6vw, 150px, 0);
  }
}