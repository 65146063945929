.root {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  color: white;
}

.rootContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.modsContainer {
  display: flex;
  color: white;
  align-items: center;
  justify-content: flex-start;
  animation: appear 0.8s;
  flex-direction: column;
  margin-bottom: 1vw;
}

@keyframes appear {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes move {
  0% {}
  100% {
    transform: translate3d(0, -200px, 0);
  }
}

.modContainer {
  animation: appear 0.8s;
}

.mod {
  background-size: contain;
  background-repeat: no-repeat;
  width: 10vw;
  height: 10vw;
}

.shaking0 {
  animation-name: shake0;
  animation-duration: 0.25s;
  animation-iteration-count: 1;
}

.shaking1 {
  animation-name: shake1;
  animation-duration: 0.25s;
  animation-iteration-count: 2;
}

.shaking2 {
  animation-name: shake2;
  animation-duration: 0.25s;
  animation-iteration-count: 3;
}

@keyframes shake0 {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake1 {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake2 {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes blurAppearAndGo {
  0% {
    transform: scale(0) rotate(180deg);
  }
  50% {
    transform: scale(1) rotate(0);
    filter: blur(0);
  }
  100% {
    transform: scale(0) rotate(-180deg);
  }
}

@keyframes blurAndAppear {
  0% {
    transform: scale(0) rotate(180deg);
  }
  50% {
    transform: scale(1.5) rotate(-20deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.blurAndoGo {
  animation: blurAppearAndGo 1s;
}

.blurAndAppear {
  animation: blurAndAppear 1s;
}