.rotationTransformContainer {
    transition: transform 0.8s linear;
}

.positionTransformContainer {
    transition: transform 1s linear;
}

.transition {
    transition: transform 0.3s;
}

.playerImageContainer {
    /* use this in player container to exactly match locations from loading */
    display: flex;
    width: 70vw;
    justify-content: space-around;
    position: absolute;
    bottom: 18.3vw;
}

.playerImage {
    width: 8vw;
    padding-top: 8vw;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0;
    display: flex;
}


.hoverPlayer {
    transform: translate3d(0, -50%, 0);
}

.selectedPlayer {
    animation: waiting 2s infinite ease-in-out;
    /* transform: translate3d(0, -50%, 0); */
}

@keyframes waiting {
    0% {
        
    }
    50% {
        transform: translate3d(0, -30%, 0);
    }
    100% {
        
    }
}
