.root {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 1.5s;
  color: white;
  font-size: 5vw;
}

.rootContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.modsContainer {
  display: flex;
  color: white;
  align-items: center; 
  justify-content: flex-start;
  animation: appear 1s;
  flex-direction: column;
}

@keyframes appear {
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes move {
  0% {
    
  }
  100% {
    transform: translate3d(0, -200px, 0);
  }
}

.modContainer {
  animation: appear 1.2s;
}

.title {
  margin-bottom: 2vw;
}

.mod {
  background-size: contain;
  background-repeat: no-repeat;
  width: 10vw;
  height: 10vw;
}