.root {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.8s;
  transition: opacity 1s 1s;
}

.graphRoot {
  display: flex;
  width: 80vw;
  height: 45vw;
  justify-content: space-around;
  align-items: flex-end;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.crown {
  width: 8vw;
  height: 8vw;
  background-size: cover;
  background-repeat: no-repeat;
  animation: crownAnim 1s;
}

.textContainer {
  font-size: 3vw;
    position: absolute;
    width: 13vw;
    text-align: center;
    left: -2.5vw;
    top: -3vw;
    overflow: hidden;
    height: 3.5vw;
    font-weight: bold;
}

@keyframes crownAnim {
  0% {
    opacity: 0;
    transform: translate3d(0, -10vw, 0) scale(1.4);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.flowerContainer {
  animation: goUp 1s;
}

@keyframes scaleAnim {
  0% {}
  50% {
    transform: scale(1.15);
  }
  100% {}
}

@keyframes goUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {}
}

.bodyContainer {
  left: -4vw;
  overflow: hidden;
  position: absolute;
  bottom: -19vw;
  opacity: 0;
  transition: opacity 1s;
}

.bodyContainerShow {
  opacity: 1;
}

.body {
  height: 22vw;
  width: 15vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}